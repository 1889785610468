// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/Blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-elements-js": () => import("./../../../src/pages/Elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-generic-js": () => import("./../../../src/pages/Generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-life-js": () => import("./../../../src/pages/Life.js" /* webpackChunkName: "component---src-pages-life-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/Portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-programming-js": () => import("./../../../src/pages/Programming.js" /* webpackChunkName: "component---src-pages-programming-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/Projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-travel-js": () => import("./../../../src/pages/Travel.js" /* webpackChunkName: "component---src-pages-travel-js" */),
  "component---src-pages-tutorials-js": () => import("./../../../src/pages/Tutorials.js" /* webpackChunkName: "component---src-pages-tutorials-js" */)
}

